import React from 'react';
import { useTranslation } from 'react-i18next';
import { TopMedia } from '../components/TopMedia/TopMedia';
import { PageHeader } from '../components/PageHeader/PageHeader';
import { ContactForm } from '../components/ContactForm/ContactForm';
import { Map } from '../components/Map/Map';

type IContactProps = {};
export const ContactView: React.FunctionComponent<IContactProps> = () => {
  const { t } = useTranslation();
  return <div>
    <TopMedia imageMedia/>
    <PageHeader>{t('menu.contact')}</PageHeader>
    <ContactForm />
    <Map />
  </div>;
};
