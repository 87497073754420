import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { TopMedia } from '../components/TopMedia/TopMedia';
import { PageHeader } from '../components/PageHeader/PageHeader';
import { ActionButton } from '../components/ActionButton/ActionButton';
import style from './WildCardView.module.scss';

type IWildCardProps = {};
export const WildCardView: React.FunctionComponent<IWildCardProps> = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const home = '/';
  const onClick = () => navigate(home);
  return <div>
    <TopMedia imageMedia />
    <PageHeader>404</PageHeader>
    <div className={style.container}>
      <ActionButton onClick={onClick}>{t(`views.wild.goBack`)}</ActionButton>
    </div>
  </div>;
};
