import React from 'react';
import { useTranslation } from 'react-i18next';
import { defaultLang } from '../../static/staticValues';

type ITranslationSwitchProps = {
  languages: string[];
  className?: string;
};

export const TranslationSwitch: React.FunctionComponent<ITranslationSwitchProps> = ({ languages, className }: any) => {
  const { i18n } = useTranslation();
  return <select
    defaultValue={languages.find((lang: string) => lang === defaultLang)}
    onChange={e => i18n.changeLanguage(e.target.value)}
    className={className}
  >
    {languages.map((lang: string) => (
      <option key={lang}>{lang}</option>
    ))}
  </select>;
};
