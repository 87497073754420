// import sampleImage from './assets/party_bgc1.jpg';
// import sampleVideo from './assets/stockPartyVideo.webm';
// import defaultLogo from './assets/logo.png';

export const websiteConfig: any = {
  // common: {
  //   logo: defaultLogo,
  //   image: sampleImage,
  //   video: sampleVideo,
  // },
  views: {
    home: {
      path: '/',
      name: 'home',
      view: 'Home',
      // mainImage: '',
      productFeatures: [
        {
          desc: 'lorem1',
          link: '1',
          title: 'lorem1',
          // img: ''
        },
        { desc: 'lorem2', title: 'lorem2' },
        { desc: 'lorem3', title: 'lorem3' },
      ],
      photos: [
        {
          src: "https://source.unsplash.com/2ShvY8Lf6l0/800x599",
        },
        {
          src: "https://source.unsplash.com/Dm-qxdynoEc/800x799",
        },
        {
          src: "https://source.unsplash.com/qDkso9nvCg0/600x799",
        },
        {
          src: "https://source.unsplash.com/iecJiKe_RNg/600x799",
        },
        {
          src: "https://source.unsplash.com/epcsn8Ed8kY/600x799",
        },
        {
          src: "https://source.unsplash.com/NQSWvyVRIJk/800x599",
        },
        {
          src: "https://source.unsplash.com/zh7GEuORbUw/600x799",
        },
        {
          src: "https://source.unsplash.com/PpOHJezOalU/800x599",
        },
        {
          src: "https://source.unsplash.com/I1ASdgphUH4/800x599",
        },
      ]
    },
    aboutUs: {
      path: '/about-us',
      name: 'aboutUs',
      view: 'SubView',
      // mainImage: '',
      photos: [
        {
          src: 'https://media.discordapp.net/attachments/951620960757374986/976237939870797864/20220517230058_1.jpg?width=1618&height=910',
        },{
          src: 'https://media.discordapp.net/attachments/951620960757374986/976237939870797864/20220517230058_1.jpg?width=1618&height=910',
        },{
          src: 'https://media.discordapp.net/attachments/951620960757374986/976237939870797864/20220517230058_1.jpg?width=1618&height=910',
        },
      ]
    },
    blog: {
      path: '/blog',
      name: 'blog',
      view: 'Blog',
      // mainImage: '',
      children: [
        {
          title: 'Title_1',
          desc: 'Desc_1',
          link: '1',
          view: 'SubView',
          // img: '',
        }
      ]
    },
    menu: {
      path: '/menu',
      name: 'menu',
      view: 'SubView',
      // mainImage: '',
      photos: [
        {
          src: "https://source.unsplash.com/2ShvY8Lf6l0/800x599",
        },
        {
          src: "https://source.unsplash.com/Dm-qxdynoEc/800x799",
        },
        {
          src: "https://source.unsplash.com/qDkso9nvCg0/600x799",
        },
        {
          src: "https://source.unsplash.com/iecJiKe_RNg/600x799",
        },
        {
          src: "https://source.unsplash.com/epcsn8Ed8kY/600x799",
        },
        {
          src: "https://source.unsplash.com/NQSWvyVRIJk/800x599",
        },
        {
          src: "https://source.unsplash.com/zh7GEuORbUw/600x799",
        },
        {
          src: "https://source.unsplash.com/PpOHJezOalU/800x599",
        },
        {
          src: "https://source.unsplash.com/I1ASdgphUH4/800x599",
        },
      ],
    },
    gallery: {
      path: '/gallery',
      name: 'gallery',
      view: 'SubView',
      // mainImage: '',
      photos: [
        {
          src: 'https://media.discordapp.net/attachments/951620960757374986/976237939870797864/20220517230058_1.jpg?width=1618&height=910',
        },{
          src: 'https://media.discordapp.net/attachments/951620960757374986/976237939870797864/20220517230058_1.jpg?width=1618&height=910',
        },{
          src: 'https://media.discordapp.net/attachments/951620960757374986/976237939870797864/20220517230058_1.jpg?width=1618&height=910',
        },{
          src: 'https://media.discordapp.net/attachments/951620960757374986/976237939870797864/20220517230058_1.jpg?width=1618&height=910',
        },{
          src: 'https://media.discordapp.net/attachments/951620960757374986/976237939870797864/20220517230058_1.jpg?width=1618&height=910',
        },{
          src: 'https://media.discordapp.net/attachments/951620960757374986/976237939870797864/20220517230058_1.jpg?width=1618&height=910',
        },
      ]
    },
    contact: {
      path: '/contact',
      name: 'contact',
      view: 'Contact',
      // mainImage: '',
    },
  },
  common: {
    footer: {
      content: `<h1>Passiflora</h1>
          <p>Dzielnica handlowa</p>
          <p>Novigrad</p>
          <p>Email: <a href='mailto:'>abc@abc.abc</a></p>`
    }
  }
};
