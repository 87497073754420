import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { image } from '../../static/staticValues';
import { Route } from '../../types/Route';
import style from './GalleryLinks.module.scss';

export type IGalleryLinksProps = {
  links: Route[];
};
export const GalleryLinks: React.FunctionComponent<IGalleryLinksProps> = ({ links }: IGalleryLinksProps) => {
  const { t } = useTranslation();
  return <section className={style.container}>
    <div className={style.container_inner}>
      {links.map((link: any, index: number) =>
        <article key={index} className={style.container_link} style={{ animationDelay: `.${index}s` }}>
          <div className={style.container_link_inner}>
            <img src={link.mainImage || image} alt='Background'/>
            <Link to={link.path}>
              <h5>{t(`menu.${link.name}`)}</h5>
            </Link>
          </div>
        </article>
      )}
    </div>
  </section>;
};
