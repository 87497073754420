import { useTranslation } from 'react-i18next';
import { logo } from '../../static/staticValues';
import style from './Footer.module.scss';

type IFooterProps = {
  content: string;
};

export const Footer = ({ content }: IFooterProps) => {
  const { t } = useTranslation();
  return <footer>
    <div className={style.container}>
      <div className={style.container_inner}>
        <div className={style.container_column}>
          <img src={logo} alt='logo' />
        </div>
        <div
          className={style.container_column}
          dangerouslySetInnerHTML={{ __html: content }}
        />
      </div>
    </div>
    <div className={style.container_copyright}>
      <h5>©2022 {t('common.copyright')} <a href='/#' target='_blank'>Tomek & Adam</a></h5>
    </div>
  </footer>;
};
