import { PhotoProvider, PhotoView } from 'react-photo-view';
import 'react-photo-view/dist/react-photo-view.css';
import style from './Gallery.module.scss';

type Photo = {
  src: string;
}
type IGalleryProps = {
  photos: Photo[];
};

export const Gallery = ({ photos }: IGalleryProps) => {
  return (
    <section className={style.container}>
      <PhotoProvider >
        {photos.map((item: Photo, index: number) => (
          <PhotoView key={index} src={item.src}>
            <article className={style.single_photo}>
              <img src={item.src} alt='' />
            </article>
          </PhotoView>
        ))}
      </PhotoProvider>
    </section>
  );
};
