import sampleImage from './../assets/party_bgc1.jpg';
import sampleVideo from './../assets/stockPartyVideo.webm';
import defaultLogo from './../assets/logo.png';
import { websiteConfig } from '../websiteConfig';

export const image: string = sampleImage;
export const logo: string = defaultLogo;
export const video: string = sampleVideo;
export const lorem: string = 'lorem';
export const defaultLang: string = 'pl';
export const mobileSize: number = 768;
export const photos = websiteConfig.views.home.photos;