import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { WildCardView } from '../views/WildCardView';
import { HomeView } from '../views/HomeView';
import { BlogView } from '../views/BlogView';
import { ContactView } from '../views/ContactView';
import { SubView } from '../views/SubView';
import { routes } from './routes';
import { Layout } from '../views/Layout';

export const SwitchRouter = () => {
  const { home, aboutUs, blog, menu, contact, gallery } = routes;
  // const renderRoutes = Object.keys(routes).map((route: string, index: number) => {
  //   const DynamicRoute: any = routes[route].view;
  //   return <>
  //     <Route path={routes[route].path} element={<DynamicRoute />} />
  //     {routes[route].children && <Route path={`${routes[route].path}/:id`} element={<SubView viewTitle={aboutUs.name} />} />}
  //   </>
  // });
  return <Routes>
    <Route path={home.path} element={<Layout />}>
      <Route path={home.path} element={<HomeView />} />
      <Route path={`/feature/:featureId`} element={<SubView />} />
      <Route path={aboutUs.path} element={<SubView viewTitle={aboutUs.name} />} />
      <Route path={blog.path} element={<BlogView data={blog} />} />
      <Route path={`${blog.path}/:blogId`} element={<SubView viewTitle={blog.name} />} />
      <Route path={menu.path} element={<SubView viewTitle={menu.name} />} />
      <Route path={gallery.path} element={<SubView viewTitle={gallery.name} />} />
      <Route path={contact.path} element={<ContactView />} />
      <Route path='*' element={<WildCardView />} />
    </Route>
  </Routes>;
};
