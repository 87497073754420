import { Link, NavLink } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TranslationSwitch } from '../TranslationSwitch/TranslationSwitch';
import { availableLanguages } from '../../i18n/i18n';
import { routes } from '../../router/routes';
import { mobileSize } from '../../static/staticValues';
import logo from '../../assets/logo.png';
import hamburgerIcon from '../../assets/Hamburger_icon.svg';
import closeIcon from '../../assets/close_icon.svg';
import style from './NavBar.module.scss';

export const NavBar = () => {
  const [mobileMenu, setMobileMenu] = useState<boolean>(false);
  const [windowDimension, detectHW] = useState<number>(window.innerWidth);
  const detectSize = () => detectHW(window.innerWidth);

  useEffect(() => {
    window.addEventListener('resize', detectSize);
    return () => window.removeEventListener('resize', detectSize);
  }, [windowDimension]);

  const { t } = useTranslation();
  const onClick = () => setMobileMenu(!mobileMenu);
  return <nav className={style.container}>
    <div className={style.container_logo}>
      <Link to={routes.home.path}>
        <img src={logo} alt='logo' />
      </Link>
      <div className={style.container_logo_burger_menu}>
        <img src={!mobileMenu ? hamburgerIcon : closeIcon} alt="" onClick={onClick} />
      </div>
    </div>
    <div className={`${style.container_links} ${(mobileMenu && windowDimension < mobileSize) && style.container_open_menu}`}>
      {Object.keys(routes).map((route: string, index: number) =>
        <NavLink
          key={`${routes[route].name}_${index}`}
          className={({ isActive }) => isActive
            ? style.container_links__link__active
            : style.container_links__link
          }
          to={routes[route].path}
        >
            {t(`menu.${routes[route].name}`)}
        </NavLink>
      )}
      <TranslationSwitch languages={availableLanguages} className={style.translation}/>
    </div>
  </nav>
};
