import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import style from './Map.module.scss';
import 'leaflet/dist/leaflet.css';

export const Map = (props: any) => {
  return <MapContainer
    center={[51.505, -0.09]}
    zoom={13}
    className={style.container}
    scrollWheelZoom={false}
  >
    <TileLayer
      attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
    />
    <Marker position={[51.505, -0.09]}>
      <Popup closeButton={false}>
        A pretty CSS3 popup. <br /> Easily customizable.
      </Popup>
    </Marker>
  </MapContainer>
};
