import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ActionButton } from '../ActionButton/ActionButton';
import commonImage from './../../assets/party_bgc1.jpg';
import { lorem } from '../../static/staticValues';
import style from './BlogItem.module.scss';

type SingleItemBlog = {
  title: string;
  desc?: string;
  img?: string;
  link: string;
}
type IBlogItemProps = {
  items: SingleItemBlog[];
};
export const BlogItem = ({ items }: IBlogItemProps) => {
  const { t } = useTranslation();
  const onClick = (nav: string) => {};
  return <section className={style.container}>
    {items.map(({ img, title, desc, link }: SingleItemBlog, index: number) =>
      <article key={index} className={style.single_blog_item} style={{ animationDelay: `.${index}s` }}>
        <div className={style.single_blog_item_image}>
          <img src={img || commonImage} alt='' />
        </div>
        <div className={style.single_blog_item_content}>
          <h1>
            <NavLink to={link}>
              {title || lorem}
            </NavLink>
          </h1>
          <p>{desc || lorem}</p>
          <ActionButton onClick={onClick}>{t('views.blog.readMore')}</ActionButton>
        </div>
    </article>)}
  </section>;
};