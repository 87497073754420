import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from './translations/en.json';
import pl from './translations/pl.json';

const resources = {
  en,
  pl
};

export const availableLanguages = Object.keys(resources);

i18n
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: 'pl',
    lng: 'pl',
    interpolation: {
      escapeValue: false
    }
  });
