import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ActionButton } from '../ActionButton/ActionButton';
import { video, image } from '../../static/staticValues';
import style from './TopMedia.module.scss';

type ITopMediaProps = {
  textButton?: string;
  imageMedia?: boolean;
  mediaSrc?: string;
  onClick?: Function;
};
export const TopMedia = ({ onClick, mediaSrc, imageMedia, textButton }: ITopMediaProps) => {
  const { t } = useTranslation();
  useEffect(() => {}, []);
  return <section className={style.container}>
    { imageMedia
      ? <div className={style.standard_media} style={{
        backgroundImage: `url(${mediaSrc || image})`
      }} />
      : <video loop autoPlay muted playsInline>
        <source type='video/webm' src={video}/>
      </video>
    }
    {textButton && <div className={style.action_button}>
        <ActionButton onClick={onClick}>{t(textButton)}</ActionButton>
    </div>}
  </section>;
};
