import React, { useEffect, useState } from 'react';
import { SwitchRouter } from '../router/SwitchRouter';
import { CookieBar } from '../components/CookieBar/CookieBar';
import { NavBar } from '../components/NavBar/NavBar';
import { Footer } from '../components/Footer/Footer';
import { ScrollToTop } from '../components/ScrollToTop/ScrollToTop';
import { websiteConfig } from '../websiteConfig';
import style from './App.module.scss';
import './../styles/commonStyles.scss';

type IAppProps = {};
export const App: React.FunctionComponent<IAppProps> = () => {
  const [config, setConfig] = useState<any>(null);
  useEffect(() => {
    setConfig(websiteConfig);
  }, []);
  const { common } = config || websiteConfig;
  return <main className={style.container}>
    <NavBar/>
    <SwitchRouter  />
    <Footer content={common.footer.content} />
    <ScrollToTop />
    <CookieBar />
  </main>;
};
