import style from './WysiwygContent.module.scss';

export type IWysiwygContentProps = {
  content?: any;
};
export const WysiwygContent = ({ content }: IWysiwygContentProps) => {
  const wysiwygContent = 'lorem';
  return <section className={style.container}>
    {content || wysiwygContent}
  </section>;
};
