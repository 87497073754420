import { useEffect, useState } from 'react';
import arrow from './../../assets/arrow-up.svg';
import style from './ScrollToTop.module.scss';

const SCROLL = 100;

export const ScrollToTop = () => {
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const scroll = () => window.scrollTo({
    top: 0,
    behavior: 'smooth',
  });

  useEffect(() => {
    const toggleVisibility = () => window.pageYOffset > SCROLL
      ? setIsVisible(true)
      : setIsVisible(false);
    window.addEventListener('scroll', toggleVisibility);
    return () => window.removeEventListener('scroll', toggleVisibility);
  }, []);
  return <div>
    {isVisible && <div className={style.container} onClick={scroll}><img src={arrow} alt="" /></div>}
  </div>;
};
