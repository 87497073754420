import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
// import { useInViewport } from 'react-in-viewport';
import { ActionButton } from '../ActionButton/ActionButton';
import { image, lorem } from '../../static/staticValues';
import style from './ProductFeatures.module.scss';

type Product = {
  img?: string;
  title: string;
  desc?: string;
  link?: string
};
type IProductFeaturesProps = {
  productsFeatures: Product[];
  referent?: any;
};
const FEATURE = '/feature';
export const ProductFeatures = ({ productsFeatures, referent }: IProductFeaturesProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const onClick = (item: string) => navigate(`${FEATURE}/${item.link}`, {
    state: { item }
  });
  // const { inViewport }: any = useInViewport(
  //   myRef,
  //   {},
  //   { disconnectOnLeave: false },
  // );
  return <section className={style.container} ref={referent}>
    <div className={style.container_inner}>
      {productsFeatures.map((feature: any, index: number) =>
        <article
          key={index}
          className={style.container_single_feature}
          style={{ animationDelay: `.${index}s` }}
        >
        <div className={style.container_single_feature_image}>
          <img src={feature.img || image} alt='img' />
        </div>
        <div className={style.container_single_feature_content}>
          <div className={style.container_single_feature_content_inner}>
            <h2>{feature?.title || lorem}</h2>
            <p>{feature?.text || lorem}</p>
            {feature?.link && <ActionButton onClick={() => onClick(feature)}>{t('views.blog.readMore')}</ActionButton>}
          </div>
        </div>
      </article>)}
    </div>
  </section>;
};
