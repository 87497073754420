import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BlogItem } from '../components/BlogItem/BlogItem';
import { TopMedia } from '../components/TopMedia/TopMedia';
import { Pagination } from '../components/Pagination/Pagination';
import { PageHeader } from '../components/PageHeader/PageHeader';

const LIMIT = 4;

type IBlogViewProps = {
  data: any
};
export const BlogView: React.FunctionComponent<IBlogViewProps> = ({ data }: IBlogViewProps) => {
  const blogItems = data.children;
  const [visiblePartOfItems, setVisiblePartOfItems] = useState(blogItems.slice(0, LIMIT));
  const { t } = useTranslation();
  const setPartOfItems = (val: number) => {
    const from = val * LIMIT;
    const currentVisibleItemsLength = LIMIT * (val + 1);
    const to = currentVisibleItemsLength > blogItems.length
      ? blogItems.length
      : currentVisibleItemsLength;
    setVisiblePartOfItems(blogItems.slice(from, to));
  };
  return <div>
    <TopMedia imageMedia />
    <PageHeader>{t('menu.blog')}</PageHeader>
    <BlogItem items={visiblePartOfItems} />
    <Pagination itemsLength={blogItems.length} paginationValue={setPartOfItems} />
  </div>;
};
