import { useTranslation } from 'react-i18next';
import { SubmitHandler, useForm } from 'react-hook-form';
import { ActionButton } from '../ActionButton/ActionButton';
import style from './ContactForm.module.scss';

type Inputs = {
  phone: string,
  name: string,
  message: string,
  email: string,
};
export const ContactForm = () => {
  const { t } = useTranslation();
  const { register, handleSubmit, formState: { errors } } = useForm<Inputs>();
  const onSubmit: SubmitHandler<Inputs> = (data: Inputs) => console.log(data);

  return <form className={style.container}>
    <section className={style.container_inner}>
      <div>
        <div className={style.container_half}>
        <span className={style.container_input}>
          <input
            className={errors.name && style.container_input_error}
            type='text'
            placeholder={`${t('views.contact.name')}*`}
            {...register('name', { required: true })}
          />
        </span>
          <br/>
          <span className={style.container_input}>
          <input
            className={errors.phone && style.container_input_error}
            type='tel'
            placeholder={`${t('views.contact.phone')}*`}
            {...register('phone', { required: true })}
          />
        </span>
          <br/>
          <span className={style.container_input}>
          <input
            className={errors.email && style.container_input_error}
            type='email'
            placeholder={`${t('views.contact.email')}*`}
            {...register('email', { required: true, pattern: /^\S+@\S+$/i })}
          />
        </span>
          <br/>
        </div>
        <div className={style.container_half}>
        <span className={style.container_input}>
          <textarea
            className={errors.message && style.container_input_error}
            id='message'
            cols={10}
            rows={10}
            placeholder={`${t('views.contact.message')}*`}
            {...register('message', { required: true })}
          />
        </span>
        </div>
      </div>
      <div className={style.container_send_button}>
        <ActionButton onClick={handleSubmit(onSubmit)}>{t('views.contact.send')}</ActionButton>
      </div>
    </section>
  </form>;
};
