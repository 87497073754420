import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ActionButton } from '../ActionButton/ActionButton';
import style from './CookieBar.module.scss';

const COOKIE_NAME = 'id';

export const CookieBar = () => {
  const { t } = useTranslation();
  const [visible, setVisible] = useState<boolean>(true);
  useEffect(() => {
    const cookieExist = document
      .cookie?.split('; ')?.find(row =>
        row.startsWith(`${COOKIE_NAME}=`)
      )?.split('=')[1];
    setVisible(!(!!cookieExist));
  }, []);
  const onClick = () => {
    setVisible(false);
    document.cookie = 'id=1; expires=';
  };
  return <section>
      {visible && <article className={style.container}>
        <div className={style.container_inner}>
          <p>{t('common.cookie')}</p>
          <ActionButton onClick={onClick}>
            {t('common.cookieAccept')}
          </ActionButton>
        </div>
      </article>}
    </section>;
};
