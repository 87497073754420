import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { TopMedia } from '../components/TopMedia/TopMedia';
import { PageHeader } from '../components/PageHeader/PageHeader';
import { WysiwygContent } from '../components/WysiwygContent/WysiwygContent';
import { Gallery } from '../components/Gallery/Gallery';
import { ProductFeatures } from '../components/ProductFeatures/ProductFeatures';
import { photos } from '../static/staticValues';

type ISubViewProps = {
  viewTitle?: string;
};
export const SubView: React.FunctionComponent<ISubViewProps> = ({ viewTitle }: ISubViewProps) => {
  const { t } = useTranslation();
  let location = useLocation();
  useEffect(() => {}, []);
  const randomTable = [
    { learnMore: true, desc: 'lorem1', title: 'lorem1' }
  ];
  return <div>
    <TopMedia imageMedia />
    <PageHeader>{viewTitle ? t(`menu.${viewTitle}`) : location.pathname}</PageHeader>
    <WysiwygContent content={'lorem2'}/>
    <ProductFeatures productsFeatures={randomTable} />
    <Gallery photos={photos}/>
  </div>;
};
