import { image } from '../../static/staticValues';
import style from './MiddleBanner.module.scss';

export type IMiddleBannerProps = {
  content: string;
  mainImage?: string;
};

export const MiddleBanner = ({ content, mainImage }: IMiddleBannerProps) => {
  return <section className={style.container} style={{
    backgroundImage: `url(${mainImage || image})`}}
  >
    <article className={style.container_inner}>
      <div className={style.container_inner_background}>
        <h1>{content}</h1>
      </div>
    </article>
  </section>;
};
