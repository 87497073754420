import React from 'react';
import style from './ActionButton.module.scss';

export type IActionButtonProps = {
  children: React.ReactChildren | HTMLElement | string;
  onClick?:  any;
  disabled?: boolean;
};
export const ActionButton = (props: IActionButtonProps) => {
  return <button
      onClick={props.onClick}
      className={style.container}
      disabled={props.disabled || false}
    >
    {props.children}
  </button>
};
