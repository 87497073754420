import React from 'react';
import style from './PageHeader.module.scss';

export type IPageHeaderProps = {
  children: React.ReactChildren | HTMLElement | string;
};
export const PageHeader = ({ children }: IPageHeaderProps) => {
  return <section className={style.container}>
    <h1>{children}</h1>
  </section>;
};
