import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { TopMedia } from '../components/TopMedia/TopMedia';
import { GalleryLinks } from '../components/GalleryLinks/GalleryLinks';
import { MiddleBanner } from '../components/MiddleBanner/MiddleBanner';
import { ProductFeatures } from '../components/ProductFeatures/ProductFeatures';
import { Gallery } from '../components/Gallery/Gallery';
import { websiteConfig } from '../websiteConfig';
import style from './HomeView.module.scss';

export type IHomePageProps = {};
export const HomeView: React.FunctionComponent<IHomePageProps> = () => {
  const { aboutUs, gallery, blog, menu, home } = websiteConfig.views;
  const { t } = useTranslation();
  useEffect(() => {}, []);
  const linksToGalleryLinks = [aboutUs, gallery, blog, menu];
  const myRef = useRef<any>(null);
  const scrollToRef = () => {
    window.scrollTo({
      top: myRef.current.offsetTop - 100,
      behavior: 'smooth',
    });
  };
  return <div className={style.container}>
    <TopMedia
      textButton={t('views.home.topBannerText')}
      onClick={scrollToRef}
    />
    <ProductFeatures productsFeatures={home.productFeatures} referent={myRef} />
    <Gallery photos={home.photos} />
    <MiddleBanner content={t('views.home.middleBannerText')} />
    <GalleryLinks links={linksToGalleryLinks} />
  </div>;
};
