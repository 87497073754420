import { useState } from 'react';
import arrow from './../../assets/arrow-up.svg';
import style from './Pagination.module.scss';

type IPaginationProps = {
  itemsLength: number;
  paginationValue: Function;
};
export const Pagination = ({ itemsLength, paginationValue }: IPaginationProps) => {
  const dataLimit = 4;
  const [pages] = useState<number>(Math.ceil(itemsLength / dataLimit));
  const [currentPage, setCurrentPage] = useState(0);

  const setView = (val: number) => {
    setCurrentPage(val);
    paginationValue(val);
  };

  const goToNextPage = () => {
    const current = currentPage + 1;
    setView(current);
  };

  const goToPreviousPage = () => {
    const current = currentPage - 1;
    setView(current);
  };

  const changePage = (event: any) => {
    const currentPage = +event.target.textContent - 1;
    setView(currentPage);
  };

  const getPaginationGroup = () => {
    let paginationButtons: number[] = [];
    for (let i = 0; i < Math.ceil(itemsLength / dataLimit); i++) paginationButtons[i] = i;
    return paginationButtons;
  };

  return <div className={style.container}>
    <button
      onClick={goToPreviousPage}
      className={`${style.prev} ${currentPage === 0 ? style.disabled : ''}`}
    >
      <img src={arrow} alt='arrow'/>
    </button>

    {getPaginationGroup().map((item: any) => (
      <button
        key={item}
        onClick={changePage}
        className={`${style.paginationItem} ${currentPage === item && style.active}`}
      >
        {item + 1}
      </button>
    ))}

    <button
      onClick={goToNextPage}
      className={`${style.next} ${(currentPage + 1) === pages && style.disabled}`}
    >
      <img src={arrow} alt='arrow'/>
    </button>
  </div>;
};
